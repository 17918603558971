<template>
    <van-popup v-model="show" position="top" >
        <div class="preview-box1">
            <div class="uploader-container">
                <van-uploader
                    
                    :preview-image="true"
                    :before-read="beforeRead"
                    :after-read="afterRead"
                    :before-delete="del"
                    v-model="fileList"
                    deletable
                    max-count="1"
                    accept="application/pdf">
                    <!--  -->
                    <!-- <van-button icon="plus" type="info" block class="button-box">上传
                    </van-button> -->
                </van-uploader>
            </div>
            <div class="tips">
                仅限上传pdf文件
            </div>
        </div>
        <div class="details-content">
            <order-detail-config 
                :details="details" 
                :category="getType()"
                :del="del"
                ></order-detail-config>
            </div>
        <order-detail-item :details="details" title="解析内容" v-if="0"></order-detail-item>
        <van-divider />
        <van-button type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认</van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    import icon from '../assets/images/icon-upload-doc.png';
    import { uploadByPieces } from '@/utils/upload'; //引入uploadByPieces方法
    import { toast } from '@/utils/common.js';
    import { getPdfInfo } from '@/api/task.js';
    // var pdf =  require('@/assets/images/file-pdf.png');
    //var word =  require('@/assets/images/file-word.png');
    // var excel =  require('@/assets/images/file-excel.png');
    // var ppt =  require('@/assets/images/file-ppt.png');
    import OrderDetailItem from '@/components/OrderDetailItem.vue';
    
    import OrderDetailConfig from '@/components/OrderDetailConfig.vue';
    export default {
        components:{
            OrderDetailItem,
            OrderDetailConfig
        },
        data() {
            return{
                show: false,
                fileList: [],
                icon: icon,
                progress: 0,
                up: false,
                type: '',
                info: null,
                url: '',
                details: null,
            }
        },
        methods:{
            del(){
                this.fileList = [];
                this.details = [];
                this.url = '';
                return true;
            },
            reset(){
               this.fileList = [];
               this.progress = 0;
               //this.info = null;
               this.url = '';
               this.details = null;
            },
            onConfirm(){
                
                console.log('file', this.url);
                if(!this.url){
                    return Notify({ type: 'warning', message: '上传文件不能为空' });
                }
                this.$parent.uploadFile({
                    info: this.details,
                    url : this.url,
                    type: this.type,
                });
                this.show = false;
                this.fileList = [];
            },
            getFiles(){
                var ulrs = [],names = [];
                for (var i = 0; i < this.fileList.length; i++) {
                    var item = this.fileList[i];
                    if(item.status == 'sucess'){
                        ulrs.push(item.file.url);
                        names.push(item.file.filename);
                    }
                }
                if(ulrs.length){
                    return {
                        file_url: ulrs.join(','),
                        file_name: names.join(','),
                    }
                }
                return null;
            },
            beforeRead(file){
                // 是否要校验文件类型
                var name = file.name;
                // console.log('file', file, name);
                var ex = name.slice(name.lastIndexOf('.') + 1);
                var type = ['pdf'];
                if(!type.includes(ex)){
                    toast('文件格式不正确');
                    return false;
                }
                return true;
            },
            afterRead(val){
                // 标志上传中
                val.status = 'uploading';
                val.message = '上传中...';
                var file = val.file;
                var _pindex = file.name.lastIndexOf('.');
                  file.filename = Date.now() + file.name.slice(_pindex);
                uploadByPieces({
                  //url: `/erp/file/upload/pdf?type=${this.type}`,
                  url: '/erp/file/upload',
                  file: file, // 视频实体
                  pieceSize: 3, // 分片大小
                  success: (data) => {
                    this.progress = 100;
                    this.up = false;
                    console.log(data);
                    val.message = '开始解析';
                    file.url = data.file_path;
                    getPdfInfo({path: data.file_full_path}, this.type).then((ret)=>{
                        this.details = ret.data;
                        this.url = data.file_path;
                        val.status = 'sucess';
                        val.message = '';
                    }).catch((err)=>{
                        console.error("解析失败！", err);
                        val.status = 'failed';
                        val.message = '解析失败';
                    })
                    // 上传成功，解析图片
                  },
                  error: (e) => {
                    console.error("上传失败！", e);
                    val.status = 'failed';
                    val.message = '上传失败';
                  },
                  uploading: (chunk, allChunk) => {
                    let st = Math.floor((chunk / allChunk) * 100);
                    this.progress = st;
                  },
                });
                return false;
            },
            getType(){
                if(this.type == 'genset'){
                    return 2;
                }else if(this.type == 'engine'){
                    return 1;
                }
            }
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
    }
    .file-item{
        position: relative;
        display: flex;
        width: 90%;
        margin: 5px 20px;
        padding-bottom: 5px;
        justify-content: space-between;
        font-size: 16px;
    }
    .item-progress{
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .tips{
        font-size: 14px;
        color:#666;
        text-align: left;
        padding: 5px 20px;
    }
</style>