<template>
    <div class="mypage order-create">
        <van-form @submit="onSubmit">
            <div style="overflow: hidden;">
                <van-divider content-position="left" dashed>基本信息</van-divider>
            </div> 
          <van-field
            required
            v-model="formData.order_no"
            name="order_no"
            label="订单编号"
            clearable
            placeholder="请填写订单编号"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            required
            v-model="formData.customer_name"
            type="textarea"
            name="customer_name"
            label="客户名称"
            rows="2"
            autosize
            placeholder="请填写客户名称"
            clearable
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            required
            readonly
            clickable
            name="order_date"
            :value="formData.order_date"
            label="下单日期"
            placeholder="选择下单日期"
            @click="showCalendar1 = true"
            :rules="[{ required: true, message: '' }]"
          />
          <van-calendar v-model="showCalendar1" @confirm="onConfirm1" :min-date="minDate"/>
          <van-field
            readonly
            clickable
            name="delivery_date"
            :value="formData.delivery_date"
            label="交付日期"
            placeholder="选择交付日期"
            @click="showCalendar2 = true"
          />
          <van-calendar v-model="showCalendar2" @confirm="onConfirm2"  :min-date="minDate"/>
          <van-field
            v-model="formData.remark"
            type="textarea"
            name="remark"
            label="订单备注"
            rows="2"
            autosize
            clearable
          />
          
          <order-detail-item :details="details1" :del="delDetail1" title="发电机组明细" v-if="0"></order-detail-item>
          <order-detail-config 
              :details="details1" 
              category="2"
              :del="delDetail1"
              >
          </order-detail-config>
          <div class="item-add-box">
            <van-button icon="plus" 
            plain hairline type="info" 
            class="add-order-detail" 
            native-type="button"
            size="small" 
            @click="popAddDetail('genset')" 
            v-if="!details1 || !details1.length">上传发电机组明细</van-button>  
          </div>
          <order-detail-config :details="details2" category="1" :del="delDetail2" ></order-detail-config>
          <order-detail-item :details="details2" :del="delDetail2" title="发动机明细" v-if="0"></order-detail-item>
          <div class="item-add-box">
            <van-button icon="plus" 
            plain hairline type="info" 
            class="add-order-detail" 
            native-type="button"
            size="small" 
            @click="popAddDetail('engine')" 
            v-if="!details2 || !details2.length">上传发动机明细</van-button>  
          </div>
          <div class="footer-box">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
        <upload-order-detail ref="detailPop"></upload-order-detail>
    </div>
</template>

<script>
    import { createErpOrder } from '../../api/task.js';
    import UploadOrderDetail from '../../components/UploadOrderDetail.vue';
    import OrderDetailItem from '../../components/OrderDetailItem.vue';
    import OrderDetailConfig from '../../components/OrderDetailConfig.vue';
    import { confirm, toast } from '../../utils/common.js'
    export default {
        components:{
            UploadOrderDetail,
            OrderDetailItem,
            OrderDetailConfig
        },
        data() {
            return {
                formData:{
                    order_no: '', // 订单编号
                    customer_name: '', // 客户名称
                    order_date: '',
                    delivery_date: '',
                    remark: '',
                    engine_pdf: '',
                    genset_pdf: '',
                },
                details1:[],
                details2:[],
                value1: '',
                value2: '',
                showCalendar1: false,
                showCalendar2: false,
                minDate: new Date('2020', 1, 1),
            }
            
        },
        methods:{
            onSubmit(){
                // 校验数据
                console.log(this.formData, this.details);
                
                var gensetNum = 0, engineNum = 0, item, details = [];
                // 发电机组
                
                for (let i = 0; i < this.details1.length; i++) {
                    item = this.details1[i];
                    gensetNum += Number(item['num']);
                    item.config = JSON.stringify(item.config);
                    details.push(item);
                }
                
                //发动机
                for (let i = 0; i < this.details2.length; i++) {
                    item = this.details2[i];
                    engineNum += Number(item['num']);
                    item.config = JSON.stringify(item.config);
                    details.push(item);
                }
                
                if(details.length == 0){
                    return toast('明细不能为空');
                }
                // 组织数据
                var extrData = {
                    order_genset_num: gensetNum,// 机组订单数量
                    order_engine_num: engineNum,// 发动机订单数量
                    made_genset_num: 0,// 生产机组数量
                    made_engine_num: 0,// 生产发动机数量
                    delivery_genset_num: 0,// 交付机组数量
                    delivery_engine_num: 0,// 交付发动机数量
                }
                var basic = Object.assign({}, this.formData, extrData);
                // 把字符串时间转为时间戳
                basic.order_date = this.getDateTime(this.formData.order_date);
                basic.delivery_date = this.getDateTime(this.formData.delivery_date);
                
                var data = {
                    basic: basic,
                    details: details
                }
                console.log(data, createErpOrder);
                createErpOrder(data).then((ret)=>{
                   console.log(ret);
                   this.clist = ret.data;
                   // 创建成功， 跳转到 详情页面
                   //var id = ret.data.id;
                   console.log(ret.data);
                   //`/erp/order-detail/${item.id}`
                   this.$router.replace(`/erp/order-detail/${ret.data.id}`)
                }).catch((err)=> {
                    toast(err.message||'创建订单失败');
                })
            },
            onConfirm1(date) {
              this.formData.order_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
              this.showCalendar1 = false;
            },
            onConfirm2(date) {
              this.formData.delivery_date= `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
              this.showCalendar2 = false;
            },
            // popAddDetail(item, index){
            //     var pop = this.$refs.detailPop;
            //     if(item){
            //         pop.formData = item;
            //         pop.index = index;
            //     }else{
            //        pop.reset(); 
            //     }
            //     pop.show = true;
            // },
            popAddDetail(type){
                var pop = this.$refs.detailPop;
                pop.reset();
                pop.type = type;
                pop.show = true;
            },
            uploadFile({info, url, type}){
                //console.log(info, url);
                if(type == 'genset'){
                    this.details1 = info;
                    this.formData.genset_pdf = url;
                }else if(type == 'engine'){
                    this.details2 = info;
                    this.formData.engine_pdf = url;
                }
            },
            delDetail1(){
                var that = this;
                confirm('确定要清除发电机组明细吗？', ()=> {
                    that.details1 = [];
                    that.formData.genset_pdf = '';
                })
                
            },
            delDetail2(){
                var that = this;
                confirm('确定要清除发动机明细吗？', ()=> {
                    that.details2 = [];
                    that.formData.engine_pdf = '';
                })
            },
            getCateName(cate){
                if(cate == 1){
                    return '发动机';
                }
                if(cate == 2){
                    return '发电机组';
                }
                return '其它';
            },
            getDateTime(str){
                if(str){
                  var time = new Date(str).getTime();
                  return Math.floor(time/1000);
                }
                return null;
            }
        }
    }
</script>

<style scoped>
    .item-add-box{
        /* text-align: center; */
        margin: 10px;
    }
    .footer-box{
        margin: 16px;
    }
    
</style>